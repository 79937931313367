.home-component-module__Y2CR_W__space {
  height: 1rem;
}

.home-component-module__Y2CR_W__nopadding {
  margin: 0 !important;
  padding: 2px 10px !important;
}

.home-component-module__Y2CR_W__filter {
  transition: filter .1s linear;
}

.home-component-module__Y2CR_W__filter:hover {
  filter: contrast(4);
  transition: filter .1s linear;
}

.home-component-module__Y2CR_W__box-container:focus {
  outline: 0;
}

.home-component-module__Y2CR_W__box-container .home-component-module__Y2CR_W__box {
  text-align: center;
  cursor: pointer;
  color: #9e9e9e;
  background-color: #fff;
  outline: 0;
  width: 100%;
  min-height: 40px;
  margin: 0 0 5px;
  padding: 6px 18px;
  font-size: 1em;
  transition: color .1s ease-in-out, background-color .1s ease-in-out;
  display: block;
  box-shadow: 1px 1px 5px #8c8c8c;
}

.home-component-module__Y2CR_W__box-container .home-component-module__Y2CR_W__box .home-component-module__Y2CR_W__icon {
  width: 80px;
  height: 100px;
  padding-top: 0;
  padding-right: 0;
  font-size: 4em;
  display: inline-block;
  position: relative;
}

.home-component-module__Y2CR_W__box-container .home-component-module__Y2CR_W__box .home-component-module__Y2CR_W__icon img {
  width: 66px;
  height: 66px;
}

.home-component-module__Y2CR_W__box-container .home-component-module__Y2CR_W__box .home-component-module__Y2CR_W__icon .home-component-module__Y2CR_W__from {
  position: absolute;
  left: -4px;
}

.home-component-module__Y2CR_W__box-container .home-component-module__Y2CR_W__box .home-component-module__Y2CR_W__icon i.home-component-module__Y2CR_W__from {
  left: 6px;
}

.home-component-module__Y2CR_W__box-container .home-component-module__Y2CR_W__box .home-component-module__Y2CR_W__icon .home-component-module__Y2CR_W__to {
  position: absolute;
  top: 35px;
  left: 20px;
}

.home-component-module__Y2CR_W__box-container .home-component-module__Y2CR_W__box .home-component-module__Y2CR_W__icon i.home-component-module__Y2CR_W__to {
  left: 32px;
}

.home-component-module__Y2CR_W__box-container .home-component-module__Y2CR_W__box .home-component-module__Y2CR_W__icon .home-component-module__Y2CR_W__gap {
  background-color: #fff;
  border-radius: 3px;
  min-width: 40px;
  min-height: 40px;
  position: absolute;
  top: 30px;
  left: 26px;
}

.home-component-module__Y2CR_W__box-container .home-component-module__Y2CR_W__box .home-component-module__Y2CR_W__icon .home-component-module__Y2CR_W__transition {
  -webkit-text-stroke: 6px rgba(242, 242, 242, 0);
  color: #545454;
  z-index: 1;
  min-width: 40px;
  min-height: 40px;
  font-size: .6em;
  position: absolute;
  top: 3px;
  left: 53px;
  transform: rotate(270deg)scale(1, -1);
}

.home-component-module__Y2CR_W__box-container .home-component-module__Y2CR_W__box .home-component-module__Y2CR_W__text-content {
  text-align: left;
  vertical-align: top;
  width: calc(100% - 104px);
  margin: 10px;
  font-size: 1em;
  font-weight: 700;
  display: inline-block;
}

.home-component-module__Y2CR_W__box-container .home-component-module__Y2CR_W__box .home-component-module__Y2CR_W__text-content .home-component-module__Y2CR_W__title {
  color: #cc1a56;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-bottom: 2px solid;
  display: block;
  overflow: hidden;
  max-width: 100% !important;
  text-decoration: none !important;
}

.home-component-module__Y2CR_W__box-container .home-component-module__Y2CR_W__box .home-component-module__Y2CR_W__text-content .home-component-module__Y2CR_W__description {
  height: 4.2em;
  font-size: .8em;
  font-weight: 400;
  line-height: 1.5em;
  overflow: hidden;
}

.home-component-module__Y2CR_W__box-container .home-component-module__Y2CR_W__box .home-component-module__Y2CR_W__availability {
  color: #282828;
  text-align: right;
  background-color: #e3e3e3;
  font-family: monospace;
  font-size: 1rem;
  position: absolute;
  bottom: .5rem;
  left: 1.2rem;
  right: 1.2rem;
}

.home-component-module__Y2CR_W__box-container .home-component-module__Y2CR_W__box .home-component-module__Y2CR_W__availability span.home-component-module__Y2CR_W__badge {
  font-size: .8rem;
}

.home-component-module__Y2CR_W__box-container .home-component-module__Y2CR_W__box:hover {
  color: #7d7d7d;
  background-color: #f8f8f8;
}

@media (max-width: 576px) {
  .home-component-module__Y2CR_W__box-container .home-component-module__Y2CR_W__box {
    width: 100%;
    font-size: 1.5em;
    display: inline-flex;
  }

  .home-component-module__Y2CR_W__box-container .home-component-module__Y2CR_W__box .home-component-module__Y2CR_W__icon {
    width: 60px;
    height: 60px;
    padding-top: 10px;
    padding-right: 18px;
    font-size: 1.5em;
    position: relative;
  }

  .home-component-module__Y2CR_W__box-container .home-component-module__Y2CR_W__box .home-component-module__Y2CR_W__icon .home-component-module__Y2CR_W__from {
    position: absolute;
    left: 2px;
  }

  .home-component-module__Y2CR_W__box-container .home-component-module__Y2CR_W__box .home-component-module__Y2CR_W__icon i.home-component-module__Y2CR_W__from {
    top: 0;
  }

  .home-component-module__Y2CR_W__box-container .home-component-module__Y2CR_W__box .home-component-module__Y2CR_W__icon img.home-component-module__Y2CR_W__from {
    width: 34px;
    top: -15px;
    left: 2px;
  }

  .home-component-module__Y2CR_W__box-container .home-component-module__Y2CR_W__box .home-component-module__Y2CR_W__icon .home-component-module__Y2CR_W__to {
    position: absolute;
    top: 20px;
  }

  .home-component-module__Y2CR_W__box-container .home-component-module__Y2CR_W__box .home-component-module__Y2CR_W__icon i.home-component-module__Y2CR_W__to {
    left: 23px;
  }

  .home-component-module__Y2CR_W__box-container .home-component-module__Y2CR_W__box .home-component-module__Y2CR_W__icon img.home-component-module__Y2CR_W__to {
    width: 32px;
    position: absolute;
    top: 3px;
    left: 18px;
  }

  .home-component-module__Y2CR_W__box-container .home-component-module__Y2CR_W__box .home-component-module__Y2CR_W__icon .home-component-module__Y2CR_W__gap {
    background-color: #fff;
    border-radius: 2px;
    min-width: 12px;
    min-height: 18px;
    position: absolute;
    top: 18px;
    left: 19px;
  }

  .home-component-module__Y2CR_W__box-container .home-component-module__Y2CR_W__box .home-component-module__Y2CR_W__icon .home-component-module__Y2CR_W__transition {
    position: absolute;
    top: -7px;
    left: 11px;
  }

  .home-component-module__Y2CR_W__box-container .home-component-module__Y2CR_W__box .home-component-module__Y2CR_W__text-content {
    margin: 0;
    font-size: .7em;
    font-weight: 700;
  }
}

.home-component-module__Y2CR_W__transform-icon {
  all: initial;
}

.home-component-module__Y2CR_W__filter-container {
  text-align: center;
}

.home-component-module__Y2CR_W__filter-container > div {
  margin-bottom: 10px;
}

.home-component-module__Y2CR_W__filter-container .home-component-module__Y2CR_W__filter-item {
  margin-right: 1rem;
}

.home-component-module__Y2CR_W__hidden {
  display: none;
}

.home-component-module__Y2CR_W__dropdown-menu-center {
  right: auto;
  top: 2rem !important;
  left: 42% !important;
  transform: translate(-50%) !important;
}

.home-component-module__Y2CR_W__dropdown-menu-center.home-component-module__Y2CR_W__sub-capability-shown {
  left: 100% !important;
}

.home-component-module__Y2CR_W__selected-high-level-capability-dropdown-item-container, .home-component-module__Y2CR_W__high-level-capability-dropdown-item-container {
  padding-left: 0;
}

.home-component-module__Y2CR_W__selected-high-level-capability-dropdown-item-container li, .home-component-module__Y2CR_W__high-level-capability-dropdown-item-container li {
  list-style: none;
}

.home-component-module__Y2CR_W__selected-high-level-capability-dropdown-item-container li {
  padding-left: 1rem;
}

.home-component-module__Y2CR_W__features {
  padding-left: 10px;
  padding-right: 10px;
}

.home-component-module__Y2CR_W__features .home-component-module__Y2CR_W__feature-container {
  padding: 5px;
}

.home-component-module__Y2CR_W__features .home-component-module__Y2CR_W__feature-container .home-component-module__Y2CR_W__box {
  border: 1px solid;
  height: 133px;
  box-shadow: 2px 2px 1px #aeaeae;
}

.home-component-module__Y2CR_W__features .home-component-module__Y2CR_W__feature-container .home-component-module__Y2CR_W__box .home-component-module__Y2CR_W__details {
  cursor: pointer;
  height: 110px;
}

.home-component-module__Y2CR_W__features .home-component-module__Y2CR_W__feature-container .home-component-module__Y2CR_W__box .home-component-module__Y2CR_W__availability {
  background: #fff9f1;
  border-top: 1px solid;
  height: 20px;
  font-size: .8rem;
  font-weight: 600;
}

.home-component-module__Y2CR_W__features .home-component-module__Y2CR_W__feature-container .home-component-module__Y2CR_W__box .home-component-module__Y2CR_W__availability .home-component-module__Y2CR_W__availability-text {
  width: calc(100% - 156px);
  padding-left: 5px;
}

.home-component-module__Y2CR_W__features .home-component-module__Y2CR_W__feature-container .home-component-module__Y2CR_W__box .home-component-module__Y2CR_W__availability .home-component-module__Y2CR_W__online, .home-component-module__Y2CR_W__features .home-component-module__Y2CR_W__feature-container .home-component-module__Y2CR_W__box .home-component-module__Y2CR_W__availability .home-component-module__Y2CR_W__offline {
  text-align: center;
  border-left: 1px solid;
  width: 78px;
  height: 20px;
  margin-right: -1px;
}

.home-component-module__Y2CR_W__features .home-component-module__Y2CR_W__feature-container .home-component-module__Y2CR_W__box .home-component-module__Y2CR_W__availability .home-component-module__Y2CR_W__online {
  color: #4c63af;
}

.home-component-module__Y2CR_W__features .home-component-module__Y2CR_W__feature-container .home-component-module__Y2CR_W__box .home-component-module__Y2CR_W__availability .home-component-module__Y2CR_W__offline {
  color: #4caf50;
}

.home-component-module__Y2CR_W__filter-box {
  background: rgba(0, 0, 0, .06);
  border: 1px solid rgba(188, 188, 188, .16);
  padding: 5px;
}

.home-component-module__Y2CR_W__filter-box .home-component-module__Y2CR_W__search-box {
  width: calc(100% - 80px);
  height: 36px;
  padding-left: 10px;
}

.home-component-module__Y2CR_W__filter-box .home-component-module__Y2CR_W__scroll {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}

.home-component-module__Y2CR_W__filter-box .home-component-module__Y2CR_W__reset-btn, .home-component-module__Y2CR_W__filter-box .home-component-module__Y2CR_W__process-type {
  color: #414141;
  cursor: pointer;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, .16);
  margin-right: 5px;
  padding: 1px 14px;
  font-weight: bold;
}

.home-component-module__Y2CR_W__filter-box .home-component-module__Y2CR_W__reset-btn:hover, .home-component-module__Y2CR_W__filter-box .home-component-module__Y2CR_W__process-type:hover {
  color: #fff !important;
  background: #3949ab !important;
}

.home-component-module__Y2CR_W__filter-box .home-component-module__Y2CR_W__reset-btn.home-component-module__Y2CR_W__active, .home-component-module__Y2CR_W__filter-box .home-component-module__Y2CR_W__process-type.home-component-module__Y2CR_W__active {
  color: #fff !important;
  background: #3f51b5 !important;
}

.home-component-module__Y2CR_W__filter-box .home-component-module__Y2CR_W__reset-btn {
  vertical-align: top;
  width: 70px;
  height: 36px;
  margin-left: 0;
  margin-right: 10px;
}

.home-component-module__Y2CR_W__filter-box .home-component-module__Y2CR_W__file-category {
  color: #fff;
  cursor: pointer;
  word-break: keep-all;
  white-space: nowrap;
  margin-right: 5px;
  padding: 2px 10px;
  font-size: .9rem;
  font-weight: bold;
}

.home-component-module__Y2CR_W__scroll::-webkit-scrollbar {
  width: 4px;
}

.home-component-module__Y2CR_W__scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 5px gray;
}

.home-component-module__Y2CR_W__scroll::-webkit-scrollbar-thumb {
  background: #414141;
  border-radius: 10px;
}

.home-component-module__Y2CR_W__scroll::-webkit-scrollbar-thumb:hover {
  background: #3a3a3a;
}

.home-component-module__Y2CR_W__from_type, .home-component-module__Y2CR_W__to_type {
  background: #3a3a3a;
}

.home-component-module__Y2CR_W__pdf {
  background: #d20000;
}

.home-component-module__Y2CR_W__archive {
  background: #3a3a3a;
}

.home-component-module__Y2CR_W__epub, .home-component-module__Y2CR_W__mobi, .home-component-module__Y2CR_W__e-book {
  background: #ffc107;
}

.home-component-module__Y2CR_W__cr2, .home-component-module__Y2CR_W__nef, .home-component-module__Y2CR_W__gif, .home-component-module__Y2CR_W__meme, .home-component-module__Y2CR_W__tiff, .home-component-module__Y2CR_W__jpeg, .home-component-module__Y2CR_W__jpg, .home-component-module__Y2CR_W__heic, .home-component-module__Y2CR_W__png, .home-component-module__Y2CR_W__webp, .home-component-module__Y2CR_W__image {
  background: #00e7c0;
}

.home-component-module__Y2CR_W__m4a, .home-component-module__Y2CR_W__aac, .home-component-module__Y2CR_W__ogg, .home-component-module__Y2CR_W__flac, .home-component-module__Y2CR_W__aiff, .home-component-module__Y2CR_W__m4r, .home-component-module__Y2CR_W__wma, .home-component-module__Y2CR_W__wav, .home-component-module__Y2CR_W__opus, .home-component-module__Y2CR_W__mp3, .home-component-module__Y2CR_W__audio {
  background: #9c27b0;
}

.home-component-module__Y2CR_W__mpg, .home-component-module__Y2CR_W__mts, .home-component-module__Y2CR_W__avi, .home-component-module__Y2CR_W__flv, .home-component-module__Y2CR_W__m4v, .home-component-module__Y2CR_W__mkv, .home-component-module__Y2CR_W__mp4, .home-component-module__Y2CR_W__mpeg, .home-component-module__Y2CR_W__mpg, .home-component-module__Y2CR_W__vob, .home-component-module__Y2CR_W__webm, .home-component-module__Y2CR_W__wmv, .home-component-module__Y2CR_W__mov, .home-component-module__Y2CR_W__av, .home-component-module__Y2CR_W__video {
  background: #673ab7;
}

.home-component-module__Y2CR_W__docx, .home-component-module__Y2CR_W__word {
  background: #005bd2;
}

.home-component-module__Y2CR_W__xlsx, .home-component-module__Y2CR_W__excel {
  background: #348651;
}

.home-component-module__Y2CR_W__pptx, .home-component-module__Y2CR_W__powerpoint {
  background: #fd5100;
}

.home-component-module__Y2CR_W__url, .home-component-module__Y2CR_W__base64, .home-component-module__Y2CR_W__text {
  background: #5e5e5e;
}

.home-component-module__Y2CR_W__ps {
  background: #c93d83;
}

.home-component-module__Y2CR_W__all {
  background: #39b6b0;
}

.home-component-module__Y2CR_W__type-text {
  color: #fff;
  padding: 2px 4px;
}

.home-component-module__Y2CR_W__file-category:hover {
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, .43);
}

.home-component-module__Y2CR_W__file-category.home-component-module__Y2CR_W__active {
  color: #fff !important;
  background: #3f51b5 !important;
}

.home-component-module__Y2CR_W__sections {
  flex-direction: column;
  gap: 15px;
  display: flex;
}

.home-component-module__Y2CR_W__sections h2 {
  text-align: center;
}

.home-component-module__Y2CR_W__sections .home-component-module__Y2CR_W__all-features .home-component-module__Y2CR_W__feature-categories {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: stretch;
  gap: 10px;
  font-size: 1rem;
  display: grid;
  overflow: hidden;
}

.home-component-module__Y2CR_W__sections .home-component-module__Y2CR_W__all-features .home-component-module__Y2CR_W__feature-categories .home-component-module__Y2CR_W__feature-category {
  border: 1px solid var(--gray-dark);
  background: var(--gray);
  border-radius: 4px;
  justify-content: space-between;
  padding: 10px;
  display: flex;
}

.home-component-module__Y2CR_W__sections .home-component-module__Y2CR_W__all-features .home-component-module__Y2CR_W__feature-categories .home-component-module__Y2CR_W__feature-category .home-component-module__Y2CR_W__feature-category-title {
  cursor: pointer;
  color: var(--primary-bg-color);
  font-size: 1.2rem;
  font-weight: bold;
  text-decoration: none;
}

.home-component-module__Y2CR_W__sections .home-component-module__Y2CR_W__all-features .home-component-module__Y2CR_W__feature-categories .home-component-module__Y2CR_W__feature-category .home-component-module__Y2CR_W__feature-category-content {
  cursor: pointer;
  color: var(--gray-darker);
  border: 1px solid var(--gray-darker);
  border-radius: 4px;
  align-items: center;
  padding: 0 10px;
  font-size: .9rem;
  text-decoration: none;
  display: flex;
}

.home-component-module__Y2CR_W__sections .home-component-module__Y2CR_W__all-features .home-component-module__Y2CR_W__feature-categories-mobile {
  display: none;
}

.home-component-module__Y2CR_W__sections .home-component-module__Y2CR_W__all-features .home-component-module__Y2CR_W__feature-subcategories {
  flex-flow: wrap;
  gap: 10px;
  margin-top: 10px;
  display: flex;
}

.home-component-module__Y2CR_W__sections .home-component-module__Y2CR_W__all-features .home-component-module__Y2CR_W__feature-subcategories .home-component-module__Y2CR_W__feature-subcategory {
  color: var(--gray-darker);
  border: 1px solid var(--gray-darker);
  cursor: pointer;
  background: #fff;
  border-radius: 20px;
  padding: 1px 12px;
  font-size: .9rem;
  text-decoration: none;
}

.home-component-module__Y2CR_W__sections .home-component-module__Y2CR_W__featured-items {
  grid-template-columns: 1fr 1fr 1fr;
  align-items: stretch;
  gap: 10px;
  font-size: 1rem;
  display: grid;
  overflow: hidden;
}

.home-component-module__Y2CR_W__sections .home-component-module__Y2CR_W__featured-items .home-component-module__Y2CR_W__featured-item {
  border: 1px solid var(--gray-dark);
  background: var(--gray);
  border-radius: 4px;
  padding: 10px;
}

.home-component-module__Y2CR_W__sections .home-component-module__Y2CR_W__featured-items .home-component-module__Y2CR_W__featured-item .home-component-module__Y2CR_W__featured-item-title {
  color: var(--primary-bg-color);
  font-weight: bold;
  text-decoration: none;
}

@media screen and (max-width: 992px) {
  .home-component-module__Y2CR_W__sections .home-component-module__Y2CR_W__all-features .home-component-module__Y2CR_W__feature-categories {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .home-component-module__Y2CR_W__sections .home-component-module__Y2CR_W__most-used-tools .home-component-module__Y2CR_W__featured-items {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 768px) {
  .home-component-module__Y2CR_W__sections .home-component-module__Y2CR_W__all-features .home-component-module__Y2CR_W__feature-categories {
    grid-template-columns: 1fr 1fr;
    display: none;
  }

  .home-component-module__Y2CR_W__sections .home-component-module__Y2CR_W__all-features .home-component-module__Y2CR_W__feature-categories-mobile {
    display: block;
  }
}

@media screen and (max-width: 576px) {
  .home-component-module__Y2CR_W__sections .home-component-module__Y2CR_W__all-features .home-component-module__Y2CR_W__feature-categories, .home-component-module__Y2CR_W__sections .home-component-module__Y2CR_W__most-used-tools .home-component-module__Y2CR_W__featured-items {
    grid-template-columns: 1fr;
  }
}

